
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { setCookie } from "typescript-cookie";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      phone: Yup.string()
        .required("Telefon no girdiğinizden emin olunuz!")
        .label("phone"),
      password: Yup.string()
        .required("Şifrenizi girdiğinizden emin olunuz!")
        .label("Password"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      let newphone: string = values.phone;
      let postphone = newphone[0] == "0" ? newphone.slice(1) : newphone;
      store
        .dispatch(Actions.LOGIN, {
          username: postphone,
          password: values.password,
        })
        .then((response) => {
          //Check if user is logged in
          if (response.status == 201) {
            Swal.fire({
              title: "Giriş Başarılı",
              text: "Hoşgeldiniz",
              icon: "success",
              showConfirmButton: false,
              timer : 1000
            }).then(() => {
              router.push({ name: "dashboard" });
            });
          } else if (response == "Authentication Error") {
            //Show error message
            Swal.fire({
              title: "Hata!",
              text: "Kullanıcı adı veya şifre hatalı",
              icon: "Error ",
              confirmButtonText: "Tamam",
            });
          } else {
            Swal.fire({
              title: "Hata!",
              text: response + " ,Error",
              icon: "Error ",
              confirmButtonText: "Tamam",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Hata!",
            text: "Kullanıcı adı veya şifre hatalı",
            icon: "error",
            confirmButtonText: "Tamam",
          });
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
